import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import LabelledSelect from './LabelledSelect';
import AutosuggestTagsInput from './AutosuggestTagsInput';
import { createEditMessagePopup, createEditProjectPopup, createEditSalesTargetPopup } from '../actions/popup';
import { createFetchDashboardProjects } from '../actions/project';
import { createSalesTargets } from '../actions';
import { getUserOptions, getTeamOptions } from '../selectors/dropdowns';
import { getUpcomingMonths, oauth } from '../utils';
import { LabeledSelect, rebuildTooltip } from './helpers';
import { ProjectTableWindow } from './projects/dashboard';
import { colors, LabeledIconInput, SearchIcon } from '@commonsku/styles';

class DashboardView extends Component {
    constructor (props) {
        super(props);

        this.shouldRerender = false;

        const months = getUpcomingMonths(2);

        this.state = {
            search_query: '',
            type: 'sales',
            jobs: props.jobs,
            client_rep_id: '',
            sales_rep_id: '',
            task_rep_id: '',
            months,
            loading: false,
            sales_targets: props.sales_targets,
            order_type: '',
            selectedClientTeams: [],
            selectedSalesTeams: [],
            selectedTaskTeams: [],
            selectedClientRepIds: [],
            selectedSalesRepIds: [],
            selectedTaskRepIds: [],
            overdue: props.jobs.filter(j => j.overdue == 1).length > 0 ?
              props.jobs.filter(j => j.overdue == 1).map(j => (j.order_type === 'SALES ORDER' || j.order_type === 'INVOICE') ? j.total_subtotal : j.total_budget).reduce((sum, t) => parseFloat(sum) + parseFloat(t))
            : 0,
            first: props.jobs.filter(j => j.overdue == 0 && j.month_inhandsdate === months[0]).length > 0 ?
              props.jobs.filter(j => j.overdue == 0 && j.month_inhandsdate === months[0]).map(j => (j.order_type === 'SALES ORDER' || j.order_type === 'INVOICE') ? j.total_subtotal : j.total_budget).reduce((sum, t) => parseFloat(sum) + parseFloat(t))
            : 0,
            second: props.jobs.filter(j => j.overdue == 0 && j.month_inhandsdate === months[1]).length > 0 ?
              props.jobs.filter(j => j.overdue == 0 && j.month_inhandsdate === months[1]).map(j => (j.order_type === 'SALES ORDER' || j.order_type === 'INVOICE') ? j.total_subtotal : j.total_budget).reduce((sum, t) => parseFloat(sum) + parseFloat(t))
            : 0,
            third: props.jobs.filter(j => j.overdue == 0 && j.month_inhandsdate === months[2]).length > 0 ?
              props.jobs.filter(j => j.overdue == 0 && j.month_inhandsdate === months[2]).map(j => (j.order_type === 'SALES ORDER' || j.order_type === 'INVOICE') ? j.total_subtotal : j.total_budget).reduce((sum, t) => parseFloat(sum) + parseFloat(t))
            : 0,
            sort: null
        };

        this.handleChangeFilter = this.handleChangeFilter.bind(this);
        this.handleOpenProjectMessagePopups = this.handleOpenProjectMessagePopups.bind(this);
        this.handleChangeTeams = this.handleChangeTeams.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleChangeTaskRep = this.handleChangeTaskRep.bind(this);
    }

    componentDidMount() {
        const { company_name } = this.props;

        //temp logging for usage
        oauth('POST', 'log', {
            company_name,
            text: 'project.php Sales Forecast'
        });
        rebuildTooltip();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // Don't rerender so that we can keep sort order
        // See https://commonsku.atlassian.net/browse/S40-1796
        if (this.shouldRerender === false && (_.isEqual(nextProps, this.props) || _.difference(nextProps, this.props).length === 0)) {
            this.setState({ loading: false });
            return;
        }

        this.setState((state) => {
          const currentJobIds = state.jobs.map(j => j.job_id).sort();
          const nextJobIds = nextProps.jobs.map(j => j.job_id).sort();
          const jobs = state.type !== 'task' && currentJobIds.length === nextJobIds.length && currentJobIds.every((value, index) => value === nextJobIds[index]) ?
            state.jobs : nextProps.jobs;
          return {
            jobs: jobs,
            sales_targets: nextProps.sales_targets,
            loading: false
          };
        }, function() {
            this.handleCalculateTotal();
        });
        if (this.state.client_rep_id !== '') {
            this.handleChangeFilter(this.state.client_rep_id, 'client_rep_id');
        }
        if (this.state.sales_rep_id !== '') {
            this.handleChangeFilter(this.state.sales_rep_id, 'sales_rep_id');
        }
        if (this.state.order_type !== '') {
            this.handleChangeFilter(this.state.order_type, 'order_type');
        }
        rebuildTooltip();

        this.shouldRerender = false;
    }

    handleReset() {
        this.setState({
            jobs: this.props.jobs,
            client_rep_id: '',
            sales_rep_id: '',
            task_rep_id: '',
            loading: false,
            order_type: '',
            selectedClientTeams: [],
            selectedSalesTeams: [],
            selectedTaskTeams: [],
            selectedClientRepIds: [],
            selectedSalesRepIds: [],
            selectedTaskRepIds: [],
            sort: null
        }, function() {
            if (this.state.type === 'task') {
                this.handleChangeTaskRep();
            }
            this.handleCalculateTotal();
        });
    }

    handleCalculateTotal() {
        const overdue = this.state.jobs.filter(j => j.overdue == 1).length > 0 ?
            this.state.jobs.filter(j => j.overdue == 1).map(j => (j.order_type === 'SALES ORDER' || j.order_type === 'INVOICE') ? j.total_subtotal : j.total_budget).reduce((sum, t) => parseFloat(sum) + parseFloat(t))
            : 0;
        const first = this.state.jobs.filter(j => j.overdue == 0 && j.month_inhandsdate === this.state.months[0]).length > 0 ?
            this.state.jobs.filter(j => j.overdue == 0 && j.month_inhandsdate === this.state.months[0]).map(j => (j.order_type === 'SALES ORDER' || j.order_type === 'INVOICE') ? j.total_subtotal : j.total_budget).reduce((sum, t) => parseFloat(sum) + parseFloat(t))
            : 0;
        const second = this.state.jobs.filter(j => j.overdue == 0 && j.month_inhandsdate === this.state.months[1]).length > 0 ?
            this.state.jobs.filter(j => j.overdue == 0 && j.month_inhandsdate === this.state.months[1]).map(j => (j.order_type === 'SALES ORDER' || j.order_type === 'INVOICE') ? j.total_subtotal : j.total_budget).reduce((sum, t) => parseFloat(sum) + parseFloat(t))
            : 0;
        const third = this.state.jobs.filter(j => j.overdue == 0 && j.month_inhandsdate === this.state.months[2]).length > 0 ?
            this.state.jobs.filter(j => j.overdue == 0 && j.month_inhandsdate === this.state.months[2]).map(j => (j.order_type === 'SALES ORDER' || j.order_type === 'INVOICE') ? j.total_subtotal : j.total_budget).reduce((sum, t) => parseFloat(sum) + parseFloat(t))
            : 0;
        /* const future = this.state.jobs.filter(j => j.future == 1).length > 0 ?
            this.state.jobs.filter(j => j.future == 1).map(j => (j.order_type === 'SALES ORDER' || j.order_type === 'INVOICE') ? j.total_subtotal : j.total_budget).reduce((sum, t) => parseFloat(sum) + parseFloat(t))
            : 0 */

        this.setState({ overdue, first, second, third });
    }

    handleChangeTeams(teams, params) {
        teams = _.uniq(teams);
        const team_list = Object.values(this.props.teams).reduce((o, t) => {o[t.team_name] = t; return o;}, {});

        let selectedUserIds = [];
        for(let team of teams) {
            if(team_list[team]) {
                selectedUserIds = _.uniq(selectedUserIds.concat(Object.values(team_list[team].users).map(u => u.user_id)));
            }
        }

        let client_reps = [];
        let sales_reps = [];
        let task_reps = [];
        if (params === 'client_rep') {
            client_reps = selectedUserIds;
            task_reps = selectedUserIds;
            if (this.state.sales_rep_id === 'group') {
                sales_reps = this.state.selectedSalesRepIds;
            }else{
                sales_reps = [this.state.sales_rep_id];
            }
        }
        if (params === 'sales_rep') {
            sales_reps = selectedUserIds;
            task_reps = selectedUserIds;
            if (this.state.client_rep_id === 'group') {
                client_reps = this.state.selectedClientRepIds;
            } else {
                client_reps = [this.state.client_rep_id];
            }
        }
        if (params === 'task_rep') {
            sales_reps = selectedUserIds;
            client_reps = selectedUserIds;
            if (this.state.task_rep_id === 'group') {
                task_reps = this.state.selectedTaskRepIds;
            } else {
                task_reps = [this.state.task_rep_id];
            }
        }

        this.setState({
            selectedClientTeams: params === 'client_rep' ? teams : this.state.selectedClientTeams,
            selectedSalesTeams: params === 'sales_rep' ? teams : this.state.selectedSalesTeams,
            selectedTaskTeams: params === 'task_rep' ? teams : this.state.selectedTaskTeams,
            selectedClientRepIds: params === 'client_rep' ? selectedUserIds : this.state.selectedClientRepIds,
            selectedSalesRepIds: params === 'sales_rep' ? selectedUserIds : this.state.selectedSalesRepIds,
            selectedTaskRepIds: params === 'task_rep' ? selectedUserIds : this.state.selectedTaskRepIds,
        }, function () {
            if (params === 'task_rep') {
                if (this.state.task_rep_id === 'group') {
                    task_reps = this.state.selectedTaskRepIds;
                } else {
                    task_reps = [this.state.task_rep_id];
                }
                this.handleChangeTaskRep(task_reps);
            }
        });
        this.handleFilterJobs(client_reps, sales_reps);
    }

    handleChangeTaskRep(task_reps=[], type=null) {
        if (this.state.type === 'task' || type === 'task') {
            this.setState({ loading: true });
            this.shouldRerender = true;
            this.props.onCreateFetchDashboardProjects('task', {
                reminder_users: this.state.task_rep_id
                    ? (this.state.task_rep_id === 'group' ? task_reps : [this.state.task_rep_id]) : '',
            });
        }
    }

    handleChangeFilter (e, field) {
        const { company_name } = this.props;

        // temp logging for usage
        if(field === 'type') {
            let tab = 'Sales Forecast';
            if(e === 'task') {
                tab = 'Tasks';
            }
            if(e === 'progress') {
                tab = 'Progress';
            }

            //temp logging for usage
            oauth('POST', 'log', {
                company_name,
                text: 'project.php ' + tab
            });

            this.shouldRerender = true;
        }

        this.setState({ [field]: e }, function() {
            let client_reps = [];
            let sales_reps = [];
            let task_reps = [];
            if (this.state.client_rep_id === 'group') {
                client_reps = this.state.selectedClientRepIds;
            } else {
                client_reps = [this.state.client_rep_id];
            }
            if (this.state.sales_rep_id === 'group') {
                sales_reps = this.state.selectedSalesRepIds;
            } else {
                sales_reps = [this.state.sales_rep_id];
            }
            if (this.state.task_rep_id === 'group') {
                task_reps = this.state.selectedTaskRepIds;
            } else {
                task_reps = [this.state.task_rep_id];
            }

            if (field === 'task_rep_id') {
                this.handleChangeTaskRep(e === 'group' && !this.state.selectedTaskRepIds.length ? task_reps : []);
            }

            if (!(
                (field === 'client_rep_id' && e === 'group' && !this.state.selectedClientRepIds.length) ||
                (field === 'sales_rep_id' && e === 'group' && !this.state.selectedSalesRepIds.length)
            )) {
                this.handleFilterJobs(client_reps, sales_reps);
            }
        });
        if (field === 'client_rep_id' && e !== '' && e !== 'group' && e !== 'inactive') {
            let need_fetch = false;
            if (this.state.sales_targets[e]) {
                let count = Object.values(this.state.sales_targets[e]).length;
                if (count < 12) {
                    need_fetch = true;
                }
            }else{
                need_fetch = true;
            }

            if (need_fetch) {
                this.props.onCreateSalesTargets(e);
            }
        }

        if (field === 'type') {
        	this.setState({ loading: true });
        	let params = e;
        	if(e === 'progress') {
        		params = 'sales';
        	}

            if (params === 'task' && this.state.task_rep_id) {
                this.handleChangeTaskRep(this.state.task_rep_id === 'group' ? this.state.selectedTaskRepIds : [this.state.task_rep_id], 'task');
            } else {
                this.props.onCreateFetchDashboardProjects(params);
            }
        }
    }

    handleFilterJobs(client_reps = [], sales_reps = []) {
        const { active_user_ids } = this.props;

        this.setState({
            jobs: (this.props.jobs || [])
                .filter(j => this.state.client_rep_id !== '' && this.state.client_rep_id !== 'inactive' && (client_reps || []).length ? (client_reps || []).indexOf(j.client_rep_id) !== -1 : this.state.client_rep_id === 'inactive' ? !active_user_ids.includes(j.client_rep_id) : true)
                .filter(j => this.state.sales_rep_id !== '' && this.state.sales_rep_id !== 'inactive' && (sales_reps || []).length ? (sales_reps || []).indexOf(j.sales_rep_id) !== -1 : this.state.sales_rep_id === 'inactive' ? !active_user_ids.includes(j.sales_rep_id) : true)
                .filter(j => this.state.order_type === 'OPEN PROJECT'
                    ? j.order_type !== 'INVOICE' && j.status_name !== 'Invoiced' && j.status_name !== 'Converted'
                    : (j.order_type === (this.state.order_type !== '' && this.state.order_type !== 'OPEN PROJECT' ? this.state.order_type : j.order_type)))
        }, function() {
            this.handleCalculateTotal();
        });
    }

    handleOpenProjectMessagePopups(j, r) {
        this.props.onCreateEditProjectPopup(j);
        /* if (r) {
            this.props.onCreateEditMessagePopup(r, 'task', j.job_id)
        } */
    }

    renderLoading() {
        return (
            <div className='row popup-content column'>
                <div className='small-12 columns'>
                    <div className='small-12 text-center'><br /><img src='/images/gears.gif' /><br /></div>
                </div>
            </div>
        );
    }

    renderSelectFilter({ placeholder, field, className='', options, }) {
        return (
            <div className={className  || `small-12 medium-${this.state.type === 'task' ? 2 : 3} columns`} style={{ padding: 4 }}>
                <LabeledSelect
                    label=''
                    placeholder={placeholder}
                    value={!this.state[field] ? null : this.state[field]}
                    options={options}
                    onChange={e => this.handleChangeFilter(e.value, field)}
                />
            </div>
        );
    }

    renderTeamInputFilter({ field, placeholder }) {
        const team_options = (this.props.team_dropdown || []).filter(v => v.value);
        let value = [];
        switch (field) {
            case 'client_rep':
                value = this.state.selectedClientTeams;
                break;
            case 'sales_rep':
                value = this.state.selectedSalesTeams;
                break;
            case 'task_rep':
                value = this.state.selectedTaskTeams;
                break;
        }
        return (
            <div className={`small-12 medium-4 columns end ${field}s`} style={{ padding: 4 }}>
                <AutosuggestTagsInput
                    placeholder={placeholder}
                    value={value}
                    tags={team_options}
                    onChange={e => this.handleChangeTeams(e, field)}
                />
            </div>
        );
    }

    render () {
        const { users, teams, team_dropdown } = this.props;
        const team_options = (team_dropdown || []).filter(v => v.value);
        const user_options = [{ key: '', value: 'All' }, { key: 'inactive', value: 'Inactive Reps'}]
            .concat(this.props.hasUserGroups && Object.values(teams).length > 0 ? [{ key: 'group', value: 'Select by group' }] : [])
            .concat(users)
            .filter(v => v.value);
        const order_types = [
            {key: '', value: 'All'},
            {key: 'OPEN PROJECT', value: 'Open Projects'},
            {key: 'OPPORTUNITY', value: 'Opportunity'},
            {key: 'PRESENTATION', value: 'Presentation'},
            {key: 'ESTIMATE', value: 'Estimate'},
            {key: 'SALES ORDER', value: 'Sales Order'},
            {key: 'INVOICE', value: 'Invoice'}
        ];

        return (
            <div className="main-content" style={{ paddingRight: '12px' }}>
                <ReactTooltip className="status-tooltip" />
                <div className="row full-width">
                    <ul className="tabs" data-tabs style={{border: 'none'}}>
                        <li className={'tabs-title' + (this.state.type === 'sales' ? ' is-active' : '')} style={this.state.is_below ? { background: 'white' } : null}>
                            <a style={{ fontSize: '14px' }} onClick={e => { e.preventDefault(); this.handleChangeFilter('sales', 'type'); }} aria-selected={this.state.type === 'sales'}>Sales Forecast</a>
                        </li>
                        <li className={'tabs-title' + (this.state.type === 'task' ? ' is-active' : '')}>
                            <a style={{ fontSize: '14px' }} onClick={e => { e.preventDefault(); this.handleChangeFilter('task', 'type'); }} aria-selected={this.state.type === 'task'}>Tasks</a>
                        </li>
                        <li className={'tabs-title' + (this.state.type === 'progress' ? ' is-active' : '')}>
                            <a style={{ fontSize: '14px' }} onClick={e => { e.preventDefault(); this.handleChangeFilter('progress', 'type'); }} aria-selected={this.state.type === 'progress'}>Progress</a>
                        </li>
                        <button type="button" className="small button" onClick={e => { e.preventDefault(); this.handleReset(); }} style={{margin: '12px 10px 0px 0px', float: 'right'}}>Reset</button>
                    </ul>
                </div>
                <div className="row collapse" style={{ maxWidth: 'initial', marginTop: '10px', fontSize: '14px', paddingBottom: 10, }}>
                    <div className={`small-12 medium-${this.state.type === 'task' ? 4 : 3} columns`} style={{ padding: 4 }}>
                        <LabeledIconInput
                            Icon={<SearchIcon />}
                            label=''
                            placeholder='Search for project'
                            value={this.state.search_query}
                            onChange={e => this.setState({ search_query: e.target.value })}
                            iconLabelStyles={{ background: 'white', }}
                            iconColor={colors.neutrals[50]}
                        />
                    </div>
                    {this.renderSelectFilter({ field: 'client_rep_id',  placeholder: 'Client Rep', options: user_options })}
                    {this.renderSelectFilter({ field: 'sales_rep_id', placeholder: 'Order Rep', options: user_options })}
                    {this.state.type === 'task'
                        ? this.renderSelectFilter({ field: 'task_rep_id', placeholder: 'Tasks by Rep', className: "small-12 medium-2 columns", options: user_options })
                        : null}
                    {this.renderSelectFilter({ field: 'order_type', placeholder: 'Stage', options: order_types })}
                    <br />
                    {this.state.client_rep_id === 'group' ?
                        this.renderTeamInputFilter({ placeholder: 'Add Client Rep group', field: 'client_rep' })
                    : null}
                    {this.state.sales_rep_id === 'group' ?
                        this.renderTeamInputFilter({ placeholder: 'Add Sales Rep group', field: 'sales_rep' })
                    : null}
                    {this.state.type === 'task' && this.state.task_rep_id === 'group' ?
                        this.renderTeamInputFilter({ placeholder: 'Add Tasks by Rep group', field: 'task_rep' })
                    : null}
                </div>
                {this.state.loading ? this.renderLoading() :
                    <React.Fragment>
                        <ProjectTableWindow
                            jobs={this.state.jobs}
                            search_query={this.state.search_query}
                            months={this.state.months}
                            type={this.state.type}
                            handleOpenProjectMessagePopups={this.handleOpenProjectMessagePopups}
                            sales_targets={this.props.hasSalesTargets ? this.state.sales_targets : []}
                            client_rep_id={this.state.client_rep_id}
                            first={this.state.first}
                            second={this.state.second}
                            third={this.state.third}
                            overdue={this.state.overdue}
                        />
                    </React.Fragment>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        jobs: Object.values(state.entities.jobs),
        users: getUserOptions(state),
        active_user_ids: state.dropdowns.active_users,
        sales_targets: state.entities.sales_targets,
        //temperory solution
        //is_admin: state.identity.role_id = '086ab0c4-047f-11e3-8851-1231392238ea',
        team_dropdown: getTeamOptions(state),
        teams: state.entities.teams,
        company_name: state.identity.company_name
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onCreateEditMessagePopup: (message, message_type, job_id) => {
            dispatch(createEditMessagePopup(message, message_type, job_id));
        },
        onCreateEditProjectPopup: (job) => {
            dispatch(createEditProjectPopup(job));
        },
        onCreateFetchDashboardProjects: (type, params = {}) => {
            dispatch(createFetchDashboardProjects(type, params));
        },
        onCreateEditSalesTargetPopup: (client_rep_id) => {
            dispatch(createEditSalesTargetPopup(client_rep_id));
        },
        onCreateSalesTargets: (client_rep_id) => {
            dispatch(createSalesTargets(client_rep_id));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardView);
